/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-card{
  margin:10px;
}

.danger{
  color:rgb(185, 17, 17);
}
